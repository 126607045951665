import React from 'react';

const Dashboard = () => {
    return (
        <div className="dashboard">
            <h2>Welcome to the Admin Dashboard</h2>
            <div className="stats">
                <div className="stat-box">
                    <h3>Active Users</h3>
                    <p>123</p>
                </div>
                <div className="stat-box">
                    <h3>Scheduled Calls</h3>
                    <p>45</p>
                </div>
                {/* Add more stat boxes as needed */}
            </div>
        </div>
    );
};

export default Dashboard;
