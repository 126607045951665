import axios from 'axios';

const API_URL = 'http://localhost:5001/admin';

export const login = async (username, password) => {
    const response = await axios.post(`${API_URL}/login`, { username, password });
    if (response.data.token) {
        localStorage.setItem('adminToken', response.data.token);
    }
    return response.data;
};

export const logout = () => {
    localStorage.removeItem('adminToken');
};

export const getToken = () => {
    return localStorage.getItem('adminToken');
};
